
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






















.hero {
  position: relative;
  height: 200vh; // need to be improve
  background-color: $c-black;
}

.hero__onboard {
  @include get-all-space;

  position: fixed;
  z-index: 99;
  display: block;
  background-color: $c-black;
}

.hero__onboard__icon.icon {
  @include center-xy;

  width: 50vw;
  height: 22rem;
  fill: $c-white;
}

.hero-inner {
  position: relative;
  z-index: 2;
}

.hero__text {
  @extend %h-wrapper;

  height: 100vh;
  padding: 0 $spacing;
  padding-top: $spacing * 8;
  color: $c-white;
  font-size: 2.6rem;
  line-height: 1.15;

  p {
    div {
      opacity: 0;
    }
  }

  strong {
    font-weight: 400;

    div {
      opacity: 1;
    }
  }

  p + p {
    margin-top: 1.2em;
  }

  @include mq(m) {
    padding: 0 $spacing * 2;
    padding-top: $spacing * 12;
    font-size: 3.6rem;
  }

  @include mq(l) {
    font-size: 4.8rem;
  }

  @include mq(xl) {
    padding-top: $spacing * 10;
    font-size: 3.8vw;
  }

  @include mq(xxxl) {
    padding-right: 0;
    padding-left: 0;
  }
}

.hero__picture-outer {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color: $c-black;

  .stop-scroll & {
    position: relative;
    margin-top: 1rem;
  }
}

.hero__picture {
  @include image-fit;

  opacity: 0;
}
