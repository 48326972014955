
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















.gallery__item {
  position: relative;
  width: 70vw;
  height: 0;
  padding-bottom: 38vw;
  margin: 0 2vw;

  img {
    @include image-fit;
  }
}
