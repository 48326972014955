
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












































.contact-inner {
  @extend %h-wrapper;

  margin: 0 auto;
  padding: $spacing * 6 $spacing $spacing * 6;

  @include mq(m) {
    display: flex;
    justify-content: space-between;
    padding: $spacing * 9 col(1) $spacing * 9;
  }

  @include mq(xl) {
    padding: $spacing * 13 col(1) $spacing * 10;
  }

  @include mq(xxxl) {
    padding: $spacing * 13 0 $spacing * 10;
  }
}

.contact__adress {
  flex-basis: col(6);
}

.contact__adress,
.contact__social__mail,
.contact__social__phone,
.contact__social__network {
  position: relative;
  display: block;
  color: $c-black;
  font-size: 3.6rem;
  line-height: 1.2;
  text-decoration: none;
  transition: transform 0.3s $ease-out-quad, color 0.3s;

  &:hover {
    transform: translateX(5%);
    color: $c-gray-light;
  }

  @include mq(m) {
    font-size: 3.8vw;
  }
}

.contact__social {
  flex-basis: col(5.6);
  padding-right: col(0.6);

  @include mq($until: m) {
    margin-top: $spacing * 2;
  }
}
