
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        










.icon {
  width: 4rem;
  height: 4rem;
  fill: $c-black;
}
