
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









.quote {
  width: 100%;
  padding: 0 20px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  @include mq(m) {
    padding: 0 40px;
  }
}

.quote svg {
  width: 30px;
  height: auto;
}

.quote p {
  @include fluid(
    font-size,
    (
      xxs: 20px,
      l: 30px,
    )
  );
}
