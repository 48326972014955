
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















.btn,
[class*='btn--'] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &[class*='--reverse'] {
    flex-direction: row-reverse;
  }
}

.btn__label {
  display: inline-block;
  padding: 0.6rem 2rem;
  color: $c-black;
  font-size: 1.8rem;
  text-decoration: none;
  border: 1px solid $c-black;
  border-radius: 5rem;
  transition: opacity 0.3s $ease-out-quad;

  :hover > & {
    opacity: 0.6;
  }

  @include mq(m) {
    padding: 1rem 2.6rem;
    font-size: 2.2rem;
  }
}

.btn__icon {
  width: 2.4rem;
  height: 2.4rem;
}
