
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        























.card {
  position: relative;
}

.card:hover {
  .image img {
    transform: scale(1.05);
  }

  .content__link {
    opacity: 0.5;
  }
}

.link {
  @include get-all-space;
  z-index: 10;
  display: block;
}

.image {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 60%;
  background: rgba(0, 0, 0, 0.3);
  overflow: hidden;

  img {
    @include image-fit;
    transition: transform 0.3s;
  }
}

.content {
  text-align: center;
}

.content__label {
  @include fluid(
    font-size,
    (
      xxs: 14px,
      l: 18px,
    )
  );
  color: $dusty-gray;
}

.content__title {
  @include fluid(
    font-size,
    (
      xxs: 24px,
      l: 32px,
    )
  );
  font-weight: 400;
}

.content__link {
  @include fluid(
    font-size,
    (
      xxs: 18px,
      l: 20px,
    )
  );
  position: relative;

  &::before {
    display: block;
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    bottom: -2px;
    height: 1px;
    background: $c-black;
  }
}
