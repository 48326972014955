
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        



























.header-inner {
  @extend %h-wrapper;

  z-index: 4;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: $spacing * 1.5 $spacing;
  align-items: center;

  .front-page & {
    @include center-x;
  }

  @include mq(m) {
    padding: $spacing * 1.5 $spacing * 2;
  }

  @include mq(xxxl) {
    padding: $spacing * 1.5 0;
  }
}

.header__icon.icon {
  width: 20.5rem;
  height: 5.7rem;

  .front-page & {
    fill: $c-white;
  }

  @include mq($until: m) {
    width: 13.5rem;
  }
}

.header__menu {
  font-size: 16px;

  a {
    font-weight: 300;
  }

  @include mq(xs) {
    font-size: 18px;
  }

  @include mq(s) {
    font-size: 22px;
  }

  @include mq(l) {
    li + li {
      margin-left: 40px !important;
    }
  }

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li + li {
    margin-left: 20px;
  }

  .front-page & {
    color: $c-white;
  }
}
