
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











.cursor {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  margin-top: -15px;
  margin-left: -15px;
  pointer-events: none;
  mix-blend-mode: difference;
  border-radius: 30px;
  opacity: 0;
  transition: opacity 0.2s ease-out;

  @include mq($until: xl) {
    display: none;
  }

  &.is-bottom {
    .cursor-inner__icon.icon {
      transform: rotate(-180deg) translate(10%, 0%);
    }
  }

  &.is-link {
    .cursor-inner__icon.icon {
      transform: rotate(-135deg) translate(10%, 0%);
    }
  }

  .cursor-inner__icon.icon {
    position: relative;
    display: block;
    fill: white;
    transform: translate(10%, 0%);
    transition: transform 0.2s $ease-out-quad;
  }
}
