
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        






.wysiwyg {
  max-width: 60rem;
  margin: 0 auto;
  margin-top: $spacing * 2;
  font-size: 1.8rem;
  line-height: 1.5;

  ul {
    @extend %list-nostyle;

    @include mq($until: m) {
      padding: $spacing;
    }

    li + li {
      margin-top: 2em;
    }
  }

  @include mq(m) {
    margin-top: $spacing * 4;
    font-size: 1.65vw;
  }

  @include mq(xl) {
    margin-top: $spacing * 6;
  }
}
