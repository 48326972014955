
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        











.duo-inner {
  @extend %h-wrapper;

  margin: 0 auto;
  margin-top: $spacing * 2;
  padding: 0 $spacing 0;

  @include mq(m) {
    display: flex;
    justify-content: space-between;
    margin-top: $spacing * 3;
    padding: 0 col(1) 0;
  }

  @include mq(xl) {
    margin-top: $spacing * 6;
  }

  @include mq(xxxl) {
    padding: 0;
  }
}

.duo__title {
  color: $c-gray-light;
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 1.3;

  @include mq(m) {
    flex-basis: col(2);
    font-size: 2.6vw;
  }
}

.duo__text {
  font-size: 1.8rem;
  line-height: 1.5;

  p + p {
    margin-top: $spacing * 2;
    padding: $spacing * 3.75 0 $spacing * 2;
    border-top: 1px solid $c-gray-lightest;
    border-bottom: 1px solid $c-gray-lightest;
  }

  @include mq(m) {
    flex-basis: col(5.6);
    padding-right: col(1.4);
    font-size: 1.65vw;
  }

  @include mq($until: m) {
    width: 80%;
    margin-top: $spacing * 2;
  }
}
