
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        

















.legals-inner {
  @extend %h-wrapper;

  display: flex;
  flex-direction: column;
  padding: $spacing * 1.5 $spacing;

  @include mq(m) {
    flex-direction: row;
    justify-content: flex-end;
    padding: $spacing $spacing * 2;
  }

  @include mq(xxxl) {
    padding: $spacing 0;
  }
}

.legals__link {
  color: $c-gray-light;
  font-size: 1.8rem;
  line-height: 1.5;
  text-decoration: none;

  &:hover {
    color: $c-black;
  }

  @include mq(m) {
    @include fluid(
      font-size,
      (
        m: 18px,
        xl: 22px,
      )
    );

    & + & {
      margin-left: $spacing * 2;
    }
  }
}
