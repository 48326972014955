
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        





.textarticle {
  width: 100%;
  padding: 0 20px;
  max-width: 800px;
  margin: 0 auto;

  @include mq(m) {
    padding: 0 40px;
  }
}

.textarticle::v-deep {
  line-height: 2;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
  }

  > * + * {
    @include fluid(
      margin-top,
      (
        xxs: 20px,
        xl: 30px,
      )
    );
  }

  a {
    text-decoration: underline;
  }

  img.size-full {
    width: 100%;
    margin: 50px 0;

    @include mq(l) {
      margin: 100px 0;
      margin-left: -100px;
      width: calc(100% + 200px);
      max-width: none;
    }
  }
}
