
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        









.not-found {
  @extend %h-wrapper;

  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 70vh;
  padding: $spacing * 1.5 $spacing;

  .btn {
    justify-content: flex-start !important;
    margin-top: $spacing * 2.5;
  }

  @include mq(m) {
    padding: $spacing * 1.5 $spacing * 2;
  }

  @include mq(xxxl) {
    padding: $spacing * 1.5 0;
  }
}

.not-found__title {
  font-weight: 400;
  line-height: 1;

  @include mq(m) {
    font-size: 3.6rem;
  }

  @include mq(l) {
    font-size: 4.8rem;
  }

  @include mq(xl) {
    font-size: 8.7vw;
  }
}

.not-found__subtitle {
  font-weight: 400;

  @include mq(m) {
    font-size: 2.4rem;
  }

  @include mq(l) {
    font-size: 3.6rem;
  }

  @include mq(xl) {
    font-size: 3.8vw;
  }
}
