//Avoid typekit FOUT
html {
  background-color: white;
  &.js {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.wf-active,
    &.wf-inactive,
    &.ready {
      opacity: 1;
    }
  }
}

// SVG sprite
body > svg {
  display: none;
}

// RWD
img {
  max-width: 100%;
  height: auto;
}

hr {
  height: 1px;
  background-color: $c-gray-lighter;
  border: 0;
}

.app {
  background-color: white;
  color: #000;
  font-family: aktiv-grotesk, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: -0.5px;
}

a,
a:hover,
a:active,
a:visited {
  color: inherit;
  text-decoration: none;
}

.cookiebar {
  z-index: 999;
}
